import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../api/axiosInstance';

export const RemoveWishlistItem = createAsyncThunk(
    'wishlist/remove',
    async (data) => {
       
            const response = await axiosInstance("POST",`${process.env.REACT_APP_MAGENTO_BASE_URL}/rest/V1/dashboard/remove-wishlist`, data);
            return response.data;
       
    }
);


const RemoveWishlistSlice = createSlice({
    name: 'remove',
    initialState: {
        data: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(RemoveWishlistItem.pending, (state) => {
                state.loading = true;
            })
            .addCase(RemoveWishlistItem.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(RemoveWishlistItem.rejected, (state, action) => {
                state.loading = false;
                state.error = "error";
            });
    }

})


export default RemoveWishlistSlice.reducer