import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ShimmerButton } from "react-shimmer-effects";

const checkoutButton = (props) => {
    const { loader, page, checkoutLink } = props
    const [goto, setGoTo] = useState(false)

    useEffect(() => {
        goto ?   setTimeout(() => {
             setGoTo(false) 
        }, 5000) : "";
    }, [goto])

    return <>
        <div className="cart-checkout">
            {!loader ? <Link  onClick={() => setGoTo(true)} to={checkoutLink}
                className="cart-secure-checkout d-flex justify-content-center align-items-center w-100 btn-outline-*">
                {goto ? "PLEASE WAIT..." : <>
                    {page == "cartPage" ?
                        <>
                            <svg width="15" height="18" viewBox="0 0 15 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.3442 6.11643H11.0394V3.48551C11.0394 1.59372 9.49501 0.0493164 7.60317 0.0493164C5.71134 0.0493164 4.16697 1.59368 4.16697 3.48551V6.1173H2.97091C1.70942 6.1173 0.6875 7.13922 0.6875 8.40071V15.0113C0.6875 16.2728 1.70942 17.2947 2.97091 17.2947H12.3442C13.6057 17.2947 14.6276 16.2728 14.6276 15.0113V8.4218C14.6276 7.13825 13.6048 6.11643 12.3442 6.11643ZM13.1919 15.0113C13.1919 15.4895 12.8003 15.859 12.3442 15.859H2.94894C2.47069 15.859 2.10117 15.4674 2.10117 15.0113V8.4218C2.10117 7.94355 2.49278 7.57403 2.94894 7.57403H12.2983C12.7765 7.57403 13.1461 7.96564 13.1461 8.4218L13.1486 15.0113H13.1919ZM5.60278 3.48551C5.60278 2.37608 6.49474 1.48499 7.6033 1.48499C8.71273 1.48499 9.60383 2.37695 9.60383 3.48551V6.1173L5.60287 6.11645L5.60278 3.48551Z"
                                    fill="white" />
                                <path
                                    d="M7.71234 9.72705C7.36404 9.72705 7.08203 10.0099 7.08203 10.3574V12.684C7.08203 13.0323 7.36491 13.3143 7.71234 13.3143C8.06063 13.3143 8.34265 13.0315 8.34265 12.684V10.3574C8.34265 10.0099 8.05976 9.72705 7.71234 9.72705Z"
                                    fill="white" />
                            </svg>
                            <p>Start Secure Checkout</p>
                        </>

                        : "CHECKOUT"}
                </>}
            </Link> : <ShimmerButton className="accordion-button" size="lg" />}
        </div>
    </>
}

export default checkoutButton