import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../api/axiosInstance';


export const getCustomerWishlist = createAsyncThunk(
    'customer/wishlist',
    async (data) => {
            const response = await axiosInstance("POST",`${process.env.REACT_APP_MAGENTO_BASE_URL}/rest/V1/dashboard/view-wishlist`, data)
            return response.data[0];
    
    }
);


const WishlistSlice = createSlice({
    name: 'wishlist',
    initialState: {
        data: {},
        loading: true,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(getCustomerWishlist.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCustomerWishlist.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getCustomerWishlist.rejected, (state, action) => {
                state.loading = false;
                state.error = "error";
            });
    }

})


export default WishlistSlice.reducer