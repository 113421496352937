import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../api/axiosInstance';


export const addProduct = createAsyncThunk(
    'add/cart',
    async (data) => {
       
            const response = await axiosInstance("POST",`${process.env.REACT_APP_MAGENTO_BASE_URL}/rest/V1/ApiHandle/cart/add`,data)
            return response.data[0];
       
    }
);

const AddToCartSlice = createSlice({
    name: 'cart',
    initialState: {
        data: [],
        loading: true,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(addProduct.pending, (state) => {
                state.loading = true;
            })
            .addCase(addProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(addProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = "error";
            });
    }

})


export default AddToCartSlice.reducer