import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../api/axiosInstance';


export const getCartProduct = createAsyncThunk(
    'cart/getProduct',
    async () => {
            const response = await axiosInstance("GET",`${process.env.REACT_APP_MAGENTO_BASE_URL}/rest/V1/ApiHandle/cart/get-item`)
            return response?.data[0];
    }
);

const cartProductSlice = createSlice({
    name: 'cart',
    initialState: {
        data: [],
        loading: true,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(getCartProduct.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCartProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getCartProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = "error";
            });
    }

})


export default cartProductSlice.reducer