import { combineReducers } from "@reduxjs/toolkit";
import searchReducer from "./searchEnableSlice";
import cartProductCountReducer from "./cartProductCountSlice";
import searchProductReducer from "./searchProductSlice";
import cartProductReducer from "./cartProductSlice";
import miniCartReducer from "./miniCartSlice";
import { apiSlice } from "./apiSlice";
import AddToCartReducer from "./AddToCartSlice";
import discountModalReducer from "./discountModalSlice";
import ordersReducer from "./ordersSlice";
import addressesReducer from "./addressesSlice";
import WishlistReducer from "./wishlistSlice";
import accountInfoReducer from "./accountInfoSlice";
import RemoveWishlistReducer from "./removeWishlistSlice";
import CategoryDataReducer from "./CategoryDataSlice";
import FullPageLoaderReducer from "./fullPageLoaderSlice";

const rootReducer = combineReducers({
  customerWishlist: WishlistReducer,
  categories: CategoryDataReducer,
  removeWishlist: RemoveWishlistReducer,
  customerAddresses: addressesReducer,
  customerAccount: accountInfoReducer,
  customerOrders: ordersReducer,
  searchEnable: searchReducer,
  discountModal: discountModalReducer,

  searchProduct: searchProductReducer,
  singleCartProduct: cartProductReducer,
  cartCount: cartProductCountReducer,
  miniCartEnabled: miniCartReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  AddToCart: AddToCartReducer,
  FullPageLoader: FullPageLoaderReducer
});

export default rootReducer;
