import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import axiosInstance from '../api/axiosInstance';




export const ProductbySearch = createAsyncThunk(
    'searchProduct/ProductbySearch',
    async (data) => {
        if(data.search) {
            const response = await axiosInstance("POST",`${process.env.REACT_APP_MAGENTO_BASE_URL}/rest/V1/products/custom-search`, data);
            return { "pageNo": data.page_no, "info": response?.data[0], value: data.search };
        }
            
        
       return { "pageNo": data.page_no, "info": response?.data[0], value: data.search };
    }
);


const searchProductSlice = createSlice({
    name: 'searchProduct',
    initialState: {
        searchValue: "",
        data: [],
        currentPage: 1,
        loading: true,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(ProductbySearch.pending, (state) => {
                state.loading = true;
            })
            .addCase(ProductbySearch.fulfilled, (state, action) => {
                state.loading = false;
                state.currentPage = action.payload.pageNo
                state.data = action.payload.info;
                state.searchValue = action.payload.value
            })
            .addCase(ProductbySearch.rejected, (state, action) => {
                state.loading = false;
                state.error = "error";
            });
    }

})


export default searchProductSlice.reducer