import axios from 'axios';
import { toast } from 'react-toastify';
import { handleLogout } from '../helper/actions';
import { useLogoutApiMutation } from '../features/apiSlice';

const axiosInstanceMain = axios.create({
  baseURL: process.env.REACT_APP_MAGENTO_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${process.env.REACT_APP_MAGENTO_API_TOKEN}`,
    'Access-Control-Allow-Headers': "X-Customer-Token"
  }
});

axiosInstanceMain.interceptors.request.use(
  config => {
    const token = process.env.REACT_APP_MAGENTO_API_TOKEN;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['X-Customer-Token'] = localStorage.getItem("customerToken") ? localStorage.getItem("customerToken") : null
    config.headers['quoteId'] = localStorage.getItem("quoteId") && localStorage.getItem("quoteId") != "undefined" ? localStorage.getItem("quoteId") : null
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const axiosInstance = async (method, url, data = null) => {

  try {
    const response = await axiosInstanceMain({
      method,
      url,
      data,
    });

    return response



  } catch (error) {

    if (error?.response?.status == 401) {

      handleLogout();
      localStorage.setItem("Session", "To access this content, please log in to your account.");
      // window.location.href = '/customer/account/login';

    } else {
      toast(error?.message, { type: "error" })
    }


    // throw error;
  }
};




export default axiosInstance;