import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../api/axiosInstance';


export const getCustomerAddresses = createAsyncThunk(
    'customer/address',
    async () => {
            const response = await axiosInstance("GET",`${process.env.REACT_APP_MAGENTO_BASE_URL}/rest/V1/dashboard/get-address`)
            return response.data[0];
    
    }
);


const AddressesSlice = createSlice({
    name: 'addresses',
    initialState: {
        data: {},
        loading: true,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(getCustomerAddresses.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCustomerAddresses.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getCustomerAddresses.rejected, (state, action) => {
                state.loading = false;
                state.error = "error";
            });
    }

})


export default AddressesSlice.reducer